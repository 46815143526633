<template>
  <section class="mr-2">
    <v-tabs
      v-if="!loading"
      class="pt-3 border"
      color="primary"
      v-model="tab"
      active-class="active"
      background-color="transparent"
    >
      <v-tab
        v-for="item in items"
        :key="item"
        class="text-capitalize poppins f14 fw600 secondary--text no-spacing"
        active-class="secondary-1--text active"
        style="text-spacing: 0px"
        :to="{
          name: `Instructor Assessment ${item == 'Assessments' ? 'Bank' : 'Rubrics'}`,
        }"
      >
        {{ item }}
      </v-tab>
      <v-spacer />
    </v-tabs>

    <circular v-else />

    <v-tabs-items v-model="tab" style="background-color: transparent">
      <router-view />
    </v-tabs-items>
  </section>
</template>

<script>
import items from '../../constants/tabs/assessment/assessmentbank'
export default {
  components: {},
  data: () => ({
    tab: null,
    items: items,
    loading: false,
  }),
};
</script>
<style scoped>
.active {
  background-color: #fff !important;
}
</style>
